<template>
  <Modal
    v-model="visible"
    @on-cancel="returnPath"
    title="修改密码">
    <Form ref="formUserPwd" :model="userItem" :rules="ruleValidate" :label-width="140">
      <Form-item label="用户名" prop="Username">
        <Input v-model="userItem.Username" :disabled="true" placeholder="请输入用户名"></Input>
      </Form-item>
      <Form-item label="原密码" prop="OldPassword">
        <Input type="password" v-model="userItem.OldPassword" placeholder="请输入原密码"></Input>
      </Form-item>
      <Form-item label="新密码" prop="Password">
        <Input type="password" v-model="userItem.Password" placeholder="请输入新密码"></Input>
      </Form-item>
      <Form-item label="重复输入新密码" prop="rePassword">
        <Input type="password" v-model="userItem.rePassword" placeholder="请重复输入新密码"></Input>
      </Form-item>
    </Form>
    <div slot="footer">
      <Button class="pull-left" size="large" @click="returnPath">取消</Button>
      <Button type="primary" size="large" :loading="submitting" @click="editUser">提交</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  props: ['show'],
  components: {
  },
  watch: {
    'show': function (newVal) {
      if (newVal) {
        this.userItem.Username = this.$store.getters.userName
        this.visible = true
      } else {
        this.visible = false
      }
    }
  },
  data () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.userItem.rePassword !== '') {
          // 对第二个密码框单独验证
          this.$refs.formUserPwd.validateField('rePassword')
        }
        callback()
      }
    }
    const validateConfirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.userItem.Password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      userItem: {
        Username: '',
        OldPassword: '',
        Password: '',
        rePassword: ''
      },
      ruleValidate: {
        Username: [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
        ],
        OldPassword: [
          { required: true, message: '原密码不能为空', trigger: 'blur' },
        ],
        Password: [
          { required: true, message: '新密码不能为空', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
        rePassword: [
          { required: true, message: '重复新密码不能为空', trigger: 'blur' },
          // { type: 'string', min: 6, message: '密码不能少于6位', trigger: 'blur' }
          { validator: validateConfirmPassword, trigger: 'blur' }
        ]
      },
      submitEnabled: true,
      submitting: false,
      visible: false
    }
  },
  mounted () {
    this.userItem.Username = this.$store.getters.userName
  },
  methods: {
    editUser () {
      this.$refs['formUserPwd'].validate((valid) => {
        if (valid) {
          this.submitting = true
          this.submitEnabled = false
          this.$Spin.show()
          let model = {
            Username: this.userItem.Username,
            OldPasswordMd5: this.$md5(this.userItem.OldPassword).toUpperCase(),
            NewPasswordMd5: this.$md5(this.userItem.Password).toUpperCase()
          }
          this.$Api.Auth(this).changePassword(model).then(() => {
            this.$Message.success('操作成功！!')
            this.$Spin.hide()
            this.submitEnabled = true
            this.submitting = false
            this.returnPath()
          }).catch(err => {
            this.$Spin.hide()
            this.$Message.error('提交失败！!' + err.message)
            this.submitEnabled = true
            this.submitting = false
          })
        } else {
          this.$Message.error('表单验证失败!')
          this.submitEnabled = true
          this.submitting = false
        }
      })
    },
    returnPath () {
      this.$emit('close')
      this.$Modal.remove()
    }
  }
}
</script>

<style>
</style>
